<template>
  <div>
    <div class='main-page-content'>
      <el-date-picker
        v-model='selectMonths'
        placeholder='月份选择'
        type='monthrange'
        format='yyyy年MM月'
        value-format='yyyy-MM'
        @change='getList'>
      </el-date-picker>

      <el-button style='margin-left: 10px' @click='getList' type='primary' icon='el-icon-search'>查询
      </el-button>
      <el-button :loading='downloadLoading'
                 v-if='hasAuthority("sign_summary_export")' type='primary' @click='exportExcel' icon='el-icon-download'>
        导出列表
      </el-button>
      <br /><br />
      <div style='display: flex' v-if='hasAuthority("sign_summary_page")'>
        <div style='flex: auto;margin-right: 10px'>
          <el-table @row-click='dbRow' :data='dataList' ref='multipleTable' row-key='id'
                    style='width: 800px;display: inline-block' id='kolAnalysisTable'
                    highlight-current-row
                    show-summary v-loading='isLading' border>
            <el-table-column align='center' label='序号' type='index' width='60'></el-table-column>
            <el-table-column align='center' prop='dept_name' show-overflow-tooltip label='部门'
                             width='120'></el-table-column>
            <el-table-column align='center' prop='sign_qty' show-overflow-tooltip label='在约红人数量'
                             sortable min-width='180'>
            </el-table-column>
            <el-table-column align='center' prop='signing_qty' sortable label='签入新人数量'
                             min-width='120'></el-table-column>
            <el-table-column align='center' prop='fired_qty' sortable label='解约账号数量' min-width='120'></el-table-column>
          </el-table>
        </div>
        <div style='flex: auto'>
          <el-card v-show="deptInfo.dept_name!=''">
            <div>
              <div>
                <h3> 部门：{{ deptInfo.dept_name }}</h3>
                <h4> 签入新人列表：</h4>
              </div>
              <el-table :data='deptInfo.signing_list'>
                <el-table-column type='index' label='序号' width='60'></el-table-column>
                <el-table-column label='红人昵称' prop='nickname'></el-table-column>
                <el-table-column label='签约日期' prop='sign_date'>
                  <template slot-scope='{row}'>
                    {{ $utils.parseTime(row.sign_date, '{y}-{m}-{d}') }}
                  </template>
                </el-table-column>
                <el-table-column label='解约日期' prop='fired_at'>
                  <template slot-scope='{row}'>
                    {{ $utils.parseTime(row.fired_at, '{y}-{m}-{d}') }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div>
              <h4> 解约账号列表：</h4>
              <el-table :data='deptInfo.fired_list'>
                <el-table-column type='index' label='序号' width='60'></el-table-column>
                <el-table-column label='红人昵称' prop='nickname'></el-table-column>
                <el-table-column label='签约日期' prop='sign_date'>
                  <template slot-scope='{row}'>
                    {{ $utils.parseTime(row.sign_date, '{y}-{m}-{d}') }}
                  </template>
                </el-table-column>
                <el-table-column label='解约日期' prop='fired_at'>
                  <template slot-scope='{row}'>
                    {{ $utils.parseTime(row.fired_at, '{y}-{m}-{d}') }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import * as XLSX from 'xlsx-js-style'
import { hasAuthority } from '@/utils/hasAuthority'

export default {
  name: 'signOverview',
  data() {
    return {
      selectMonths: [],
      dataList: [],
      isLading: false,
      downloadLoading: false,
      today: '',
      deptInfo: {
        //部门名
        dept_id: null,
        dept_name: null,
        //签入新人数量
        signing_list: [],
        // 解约账号数量
        fired_list: []

      }
    }
  },

  components: {
    ExportBtn
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    hasAuthority,
    async dbRow(row) {
      let dept_id = row.dept_id || ''
      if (dept_id && this.selectMonths && this.selectMonths.length > 0) {
        let { dept_info, signing_list, fired_list } = await this.$api.getSignSummaryDetail({
          dept_id: dept_id,
          date_range: this.selectMonths
        })
        this.deptInfo = {
          dept_id: dept_info ? dept_info.dpt_id : '',
          dept_name: dept_info ? dept_info.name : '',
          signing_list: signing_list,
          fired_list: fired_list
        }
      }
    },
    async getList() {
      this.isLading = true
      this.dataList = []
      let params = this.selectMonths && this.selectMonths.length === 2 ? { date_range: this.selectMonths } : {}
      let { list } = await this.$api.signInfoOverview(params)
      this.dataList = list || []
      this.isLading = false
      if (this.dataList) {
        await this.dbRow(this.dataList[0])
      }

    },
    defaultTime() {
      const date = new Date()
      //年份选择：默认当月
      let end = date.getFullYear() + '-' + (Number(date.getMonth() + 1) < 10 ? '0' + Number(date.getMonth() + 1) : date.getMonth() + 1)
      let start = date.getFullYear() + '-' + '01'
      this.selectMonths = [start, end]
    },
    exportExcel: function() {
      this.downloadLoading = true
      const xlsxParam = { raw: false } //转换成excel时，使用原始的格式
      /* 生成工作表 */
      const workbook = XLSX.utils.book_new()
      /* 从表生成工作簿对象 */

      let worksheet = XLSX.utils.table_to_sheet(
        document.querySelector('#kolAnalysisTable'),
        xlsxParam
      )

      /* 设置工作簿样式 */
      worksheet = this.worksheetStyle(worksheet)

      /* 工作簿导出 */
      XLSX.utils.book_append_sheet(workbook, worksheet, '招商情况一览')
      XLSX.writeFile(workbook, `招商情况一览.xlsx`)

      this.downloadLoading = false
    },
    /* 设置工作簿样式 */
    worksheetStyle: function(worksheet) {
      // 表样式设置
      const colsLength = 14
      let cols = [] // 设置每列的宽度
      // wpx 字段表示以像素为单位，wch 字段表示以字符为单位
      for (let i = 0; i <= colsLength; i++) {
        let col = {}

        if (i == 0) {
          col.wch = 30
          col.wpx = 50
        } else if (i == 1) {
          col.wch = 50

        } else {
          col.wch = 18
        }
        cols.push(col)
      }
      worksheet['!cols'] = cols // 设置列宽信息到工作表
      //以下是样式设置，样式设置放在组织完数据之后，xlsx-js-style的核心API就是SheetJS的
      Object.keys(worksheet).forEach(key => {

        // 非!开头的属性都是单元格
        if (!key.startsWith('!')) {
          if (key.indexOf('A') != -1 || ['A1', 'B1', 'C1', 'D1', 'E1'].includes(key)) {
            if (key.indexOf('A') != -1) {
              if (key === 'A1') {
                worksheet[key].s = {
                  wpx: 50,
                  font: {
                    sz: '14',
                    name: 'Microsoft YaHei',
                    color: { rgb: 'FFFFFF' }

                  },
                  alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: true
                  },
                  border: {
                    top: { style: 'thin' },
                    right: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' }
                  },
                  fill: {
                    fgColor: { rgb: 'ff3176' }
                  }
                }
              } else
                worksheet[key].s = {
                  font: {
                    sz: '12',
                    name: 'Microsoft YaHei'
                  },
                  alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: true
                  },
                  border: {
                    top: { style: 'thin' },
                    right: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' }
                  }
                }
            } else {
              worksheet[key].s = {
                font: {
                  sz: '12',
                  color: { rgb: 'FFFFFF' },
                  name: 'Microsoft YaHei'
                },
                alignment: {
                  horizontal: 'center',
                  vertical: 'center',
                  wrapText: true
                },
                border: {
                  top: { style: 'thin' },
                  right: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' }
                },
                fill: {
                  fgColor: { rgb: 'ff3176' }
                }
              }
            }
            worksheet[key].t = 's'
          } else if (key.indexOf('F') != -1) {
            worksheet[key].s = {
              font: {
                sz: '14',
                name: 'Microsoft YaHei',
                color: { rgb: 'FFFFFF' }

              },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
              },
              border: {
                // top: { style: 'thin' },
                // right: { style: 'thin' },
                // bottom: { style: 'thin' },
                // left: { style: 'thin' }
              },
              fill: {
                fgColor: { rgb: 'ff3176' }
              }
            }
          } else {
            worksheet[key].s = {
              font: {
                sz: '14'
              },
              alignment: {
                horizontal: 'right',
                vertical: 'right',
                wrapText: true
              },
              border: {
                top: { style: 'thin' },
                right: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' }
              }
            }
            worksheet[key].t = 'n'
            worksheet[key].s.numFmt = 0
          }
        }

      })

      return worksheet
    }

  },
  mounted() {
    this.getList()
  },
  created() {
    this.defaultTime()
  }
}
</script>

<style scoped lang='scss'>
.card_info {
  margin-left: 10px;
  //width: 300px;
  display: inline-block;

  .el-card__body > div {
    margin: 10px 0;
  }
}
</style>
